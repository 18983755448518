import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../../axiosInstance";
import API from "../../api";
import Filter from "./Filter";
import ActionCellMenu from "../../Components/ActionCellMenu";
import { AppContext } from "../../Context/AppContext";

import { CircularProgress, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import {
  VisibilityOutlined as VisibilityOutlinedIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  BorderColorOutlined as BorderColorOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import StateBody from "../../Components/Columns/StateBody";
import DateBody from "../../Components/Columns/DateBody";
import RemoveDialog from "../../Components/RemoveDialog";
import { addCommas } from "../../lib";

function Vehicles({ permissions }) {
  const navigate = useNavigate();
  const { setShowBackButton } = useContext(AppContext);
  const [DataTable, setDataTable] = useState([
    {
      id: 1,
      currentRental: {
        status: "active",
      },
      _id: "6723bee07972256750366c10",
      carId: "CAR00122",
      vinNumber: "1HGCM82633A0043522222",
      brand: "Toyota",
      model: "Camry",
      color: "Silver",
      mileage: 15000,
      nextMaintenanceDate: "2024-12-15T00:00:00.000Z",
      rentalPrice: 50,
      state: "available",
      media: [
        {
          url: "https://res.cloudinary.com/beirut-mart/image/upload/v1730395866/skyline-car-rental-media/iqwqwvze8ieezlndkggi.png",
          public_id: "skyline-car-rental-media/iqwqwvze8ieezlndkggi",
          resource_type: "image",
          _id: "6723bee07972256750366c11",
        },
        {
          url: "https://res.cloudinary.com/beirut-mart/image/upload/v1730395866/skyline-car-rental-media/yerskslizca3pnowbewp.png",
          public_id: "skyline-car-rental-media/yerskslizca3pnowbewp",
          resource_type: "image",
          _id: "6723bee07972256750366c12",
        },
        {
          url: "https://res.cloudinary.com/beirut-mart/image/upload/v1730395866/skyline-car-rental-media/awrwqcasb7svb1ewzwft.png",
          public_id: "skyline-car-rental-media/awrwqcasb7svb1ewzwft",
          resource_type: "image",
          _id: "6723bee07972256750366c13",
        },
        {
          url: "https://res.cloudinary.com/beirut-mart/image/upload/v1730395866/skyline-car-rental-media/voech89wepfsdexsmwhv.png",
          public_id: "skyline-car-rental-media/voech89wepfsdexsmwhv",
          resource_type: "image",
          _id: "6723bee07972256750366c14",
        },
        {
          url: "https://res.cloudinary.com/beirut-mart/video/upload/v1730395870/skyline-car-rental-media/onypzzpia0vqgvqsf7rr.mp4",
          public_id: "skyline-car-rental-media/onypzzpia0vqgvqsf7rr",
          resource_type: "video",
          _id: "6723bee07972256750366c15",
        },
        {
          url: "https://res.cloudinary.com/beirut-mart/image/upload/v1730395870/skyline-car-rental-media/dwl4dcegxmeocmxcyefg.jpg",
          public_id: "skyline-car-rental-media/dwl4dcegxmeocmxcyefg",
          resource_type: "image",
          _id: "6723bee07972256750366c16",
        },
      ],
      maintenanceTasks: [],
      rentalHistory: [],
      createdAt: "2024-10-31T17:31:12.046Z",
      updatedAt: "2024-10-31T17:31:12.046Z",
      __v: 0,
    },
  ]);
  const [FilterData, setFilterData] = useState({
    Brands: [],
    Models: [],
    Status: [],
  });
  const [filterValues, setFilterValues] = useState({
    search: undefined,
    brands: [],
    models: [],
    status: [],
    priceMin: undefined,
    priceMax: undefined,
  });
  const [OpenRemove, setOpenRemove] = useState(false);
  const [RowData, setRowData] = useState({});
  const [Refresh, setRefresh] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setShowBackButton(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(API.vehicles, {
        params: {
          search: filterValues?.search,
          brands: filterValues?.brands?.join(","),
          models: filterValues?.models?.join(","),
          status: filterValues?.status?.join(","),
          priceMin: filterValues?.priceMin,
          priceMax: filterValues?.priceMax,
        },
      })
      .then((res) => {
        setTotalRecords(res?.data?.count);
        let data = res?.data?.data?.map((val) => ({
          ...val,
          id: val?._id,
        }));
        setDataTable([...data]);
        setLoading(false);
      })
      .catch((err) => {
        setTotalRecords(0);
        setDataTable([]);
        setLoading(false);
      });
  }, [filterValues, Refresh, page]);
  useEffect(() => {
    axiosInstance.get(API.vehicles).then((res) => {
      let data = res?.data?.data?.map((val) => ({
        ...val,
        id: val?._id,
      }));
      setFilterData({
        Brands: [...new Set(data?.map((ele) => ele?.brand))],
        Models: [...new Set(data?.map((ele) => ele?.model))],
        Status: [...new Set(data?.map((ele) => ele?.state))],
      });
    });
  }, [Refresh]);
  const colors = {
    available: "#0F930F",
    unavailable: "#EF0A0A",
    rented: "#EF0A0A",
    in_maintenance: "#FF9407",
  };
  const columns = [
    {
      field: "image",
      headerName: "Picture",
      align: "center",
      headerAlign: "center",
      flex: 1,
      hideSortIcons: true,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={params?.row?.media[0]?.url}
            width="50px"
            height={"50px"}
            style={{ borderRadius: 2, cursor: "pointer", objectFit: "contain" }}
            onLoad={() => {}}
            onError={(e) => {
              e.currentTarget.onerror = null; // prevents looping
              e.currentTarget.src = "/images/car.jpeg";
              // e.currentTarget.style = 'display: none';
              //e.target.style.display = 'none';
            }}
            // onClick={() => {
            //   setRowData(params?.row);
            //   setOpenDialog(true);
            // }}
          />
        </Stack>
      ),
    },
    {
      field: "carId",
      headerName: "Car ID",
      align: "left",
      headerAlign: "left",
      flex: 2,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      editable: true,
      //renderEditCell: (params) => <CustomTypeEditComponent {...params} />,
    },
    {
      field: "vinNumber",
      headerName: "VIN Number",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      editable: true,
    },
    {
      field: "brand",
      headerName: "Brand",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      editable: true,
    },
    {
      field: "model",
      headerName: "Model",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      editable: true,
    },
    {
      field: "color",
      headerName: "Color",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      editable: true,
    },
    {
      field: "mileage",
      headerName: "Next maintenance Mileage",
      align: "left",
      headerAlign: "left",
      flex: 2,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params) => (
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          spacing={1}
          sx={{ height: "100%", color: "#000" }}
        >
          <Typography variant="subtitle2">
            {addCommas(params?.row?.mileage || 0)} &nbsp; miles
          </Typography>
        </Stack>
      ),
    },
    // {
    //   field: "nextMaintenanceDate",
    //   headerName: "Next Maintenance Date",
    //   align: "left",
    //   headerAlign: "left",
    //   flex: 2,
    //   hideSortIcons: false,
    //   disableColumnMenu: true,
    //   sortable: true,
    //   renderCell: (params) => (
    //     <DateBody value={params?.row?.nextMaintenanceDate} />
    //   ),
    // },
    {
      field: "rentalPrice",
      headerName: "Total Price",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params) => (
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          spacing={1}
          sx={{ height: "100%", color: "#000" }}
        >
          <Typography variant="subtitle2">
            ${addCommas(params?.row?.rentalPrice)}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "state",
      headerName: "State",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params) => (
        <StateBody
          color={colors[params?.row?.state]}
          value={params?.row?.state?.replace("_", " ")}
        />
      ),
    },
    {
      field: "",
      headerName: "Actions",
      align: "center",
      headerAlign: "center",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      disableExport: true,
      sortable: false,
      renderCell: (params) => (
        <>
          {!permissions?.vehicles?.view &&
          !permissions?.vehicles?.edit &&
          !permissions?.vehicles?.delete ? (
            <small>Not authorized</small>
          ) : (
            <ActionCellMenu
              menuItems={[
                {
                  icon: <VisibilityOutlinedIcon />,
                  label: "View",
                  onClick: () => {
                    setShowBackButton(true);
                    navigate(`/vehicles/details/${params?.row?._id}`);
                  },
                  hide: !permissions?.vehicles?.view,
                },
                {
                  icon: <SettingsOutlinedIcon />,
                  label: "Maintenance",
                  onClick: () => {
                    setShowBackButton(true);
                    navigate(`/vehicles/maintenance/${params?.row?._id}`);
                  },
                },
                {
                  icon: <BorderColorOutlinedIcon />,
                  label: "Edit",
                  onClick: () => {
                    navigate(`/vehicles/edit/${params?.row?._id}`);
                  },
                  hide: !permissions?.vehicles?.edit,
                },
                {
                  icon: <DeleteOutlinedIcon color="secondary" />,
                  label: "Delete",
                  color: "secondary",
                  onClick: () => {
                    setRowData(params?.row);
                    setOpenRemove(true);
                  },
                  hide: !permissions?.vehicles?.delete,
                },
              ]}
            />
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Filter
            FilterData={FilterData}
            setFilterValues={setFilterValues}
            filterValues={filterValues}
            showCreateBtn={permissions?.vehicles?.create}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          {Loading ? (
            <CircularProgress />
          ) : (
            <DataGrid
              rows={DataTable || []}
              columns={columns}
              style={{ overflow: "auto" }}
              pagination
              pageSize={pageSize}
              rowLength={totalRecords}
              pageSizeOptions={[5, 10, 25]}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: pageSize, page: page },
                },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even"
              }
              sx={{
                borderColor: "#fff",
                color: "#201D23CC",
                lineHeight: "19.6px",
                fontSize: "14px",
                fontFamily: "Montserrat",
                "& .even": {
                  backgroundColor: "#FAFAFA !important",
                },
                "& .odd": {
                  backgroundColor: "#FFF !important",
                },
                "& .MuiDataGrid-cell": {
                  border: "unset",
                  fontFamily: "Montserrat",
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#FAFAFA !important",
                  borderBottom: "unset !important",
                },

                "& .MuiTablePagination-spacer": {
                  flex: "0 !important",
                },
                "& .MuiInputBase-root": {
                  marginRight: "auto !important",
                },
              }}
              disableRowSelectionOnClick
            />
          )}
        </Grid>
      </Grid>
      <RemoveDialog
        open={OpenRemove}
        setOpen={setOpenRemove}
        endpoint={API.vehicles}
        itemId={RowData?._id}
        setRefresh={setRefresh}
        Refresh={Refresh}
      />
    </>
  );
}
export default Vehicles;
