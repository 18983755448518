import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Stack,
  Typography,
  Button,
  Card,
  CircularProgress,
  Pagination,
  Checkbox,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import API from "../../api";
import { AppContext } from "../../Context/AppContext";
import { addCommas } from "../../lib";
import VehiclesFilter from "../Vehicles/Filter";
import CustomersFilter from "../Customers/Filter";
import { Calendar } from "react-multi-date-picker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import "./index.css";
import { DatePicker } from "@mui/x-date-pickers";

function CreateOrder() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { setShowBackButton } = useContext(AppContext);
  const [openAddCustomer, setOpenAddCustomer] = useState(false);
  const [openSelectDate, setOpenSelectDate] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [SelectedVehicle, setSelectedVehicle] = useState({});
  const [CustomSelectedVehicle, setSelectedVehicleCustom] = useState({});
  const [SelectedDates, setSelectedDates] = useState([new Date(), new Date()]);
  // ([
  //   ["2024-11-19T23:56:03.885Z", "2024-11-26T23:56:03.885Z"],
  //   ["2024-11-26T12:51:22.770Z", "2024-11-30T12:51:22.770Z"],
  //   ["2024-11-15T15:43:33.565Z", "2024-11-20T15:43:33.565Z"],
  // ]);
  const [Error, setError] = useState({
    isError: false,
    errorMessage: "",
    errors: "",
  });
  // console.log(
  //   "SelectedDates ",
  //   SelectedDates?.map((ele) => new Date(ele))
  // );
  const [Refresh, setRefresh] = useState(false);
  const [FilterData, setFilterData] = useState({
    Brands: [],
    Models: [],
    Status: [],
  });
  const [AllCustomers, setAllCustomers] = useState([]);
  const [filterValues, setFilterValues] = useState({
    // customer filter
    name: undefined,
    phoneNumber: undefined,
    email: undefined,
    // vehicle filter
    search: undefined,
    brands: [],
    models: [],
    status: [],
    priceMin: undefined,
    priceMax: undefined,
  });
  const [AllVehicles, setAllVehicles] = useState([]);
  const [AllVehiclesBookedDates, setAllVehiclesBookedDates] = useState({});
  const [Vehicles, setVehicles] = useState([]);
  const [TotalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const rowsPerPage = 12;
  const [SelectedCars, setSelectedCars] = useState([]);
  const [LoadingAddCustomer, setLoadingAddCustomer] = useState(false);
  const [CustomerData, setCustomerData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    dateOfBirth: "",
    policyNumber: "",
    paymentMethod: "",
    status: "",
    image: undefined,
    orders: [],
    _id: "",
  });
  const [VehiclesDates, setVehiclesDates] = useState({});

  //#region useEffect
  useEffect(() => {
    setShowBackButton(true);
  }, []);
  useEffect(() => {
    setAllVehiclesBookedDates({});
    axiosInstance.get(API.vehicles).then((res) => {
      let data = res?.data?.data?.map((val) => ({
        ...val,
        id: val?._id,
      }));
      setAllVehicles(data);
      data?.forEach((ele) => {
        setAllVehiclesBookedDates((prev) => ({
          ...prev,
          [ele?._id]: ele?.rentalHistory?.map((h) => {
            let car = h?.orderId?.cars?.length > 0 ? h?.orderId?.cars[0] : [];
            return [
              new Date(car?.pickupDateTime),
              new Date(car?.returnDateTime),
            ];
          }),
        }));
      });
      setFilterData({
        Brands: [...new Set(data?.map((ele) => ele?.brand))],
        Models: [...new Set(data?.map((ele) => ele?.model))],
        Status: [...new Set(data?.map((ele) => ele?.state))],
      });
    });
  }, []);
  useEffect(() => {
    // get all customers
    setAllCustomers([]);
    axiosInstance.get(API.customers).then((res) => {
      setAllCustomers(res?.data?.data || []);
    });
  }, [Refresh]);
  useEffect(() => {
    if (
      filterValues?.name ||
      filterValues?.phoneNumber ||
      filterValues?.email
    ) {
      axiosInstance
        .get(API.customers, {
          params: {
            name: filterValues?.name,
            phoneNumber: filterValues?.phoneNumber,
            email: filterValues?.email,
          },
        })
        .then((res) => {
          const data = res?.data?.data;
          if (data?.length > 0) {
            setCustomerData({
              name: data[0]?.name,
              phoneNumber: data[0]?.phoneNumber,
              email: data[0]?.email,
              dateOfBirth: data[0]?.dateOfBirth,
              policyNumber: data[0]?.policyNumber,
              paymentMethod: data[0]?.paymentMethod,
              status: data[0]?.status,
              image: data[0]?.image,
              orders: data[0]?.orders,
              _id: data[0]?._id,
            });
          }
        });
    }
  }, [filterValues.name, filterValues.email, filterValues.phoneNumber]);
  useEffect(() => {
    setLoading(true);
    setVehicles([]);
    axiosInstance
      .get(API.vehicles, {
        params: {
          search: filterValues?.search,
          brands: filterValues?.brands?.join(","),
          models: filterValues?.models?.join(","),
          status: filterValues?.status?.join(","),
          priceMin: filterValues?.priceMin,
          priceMax: filterValues?.priceMax,
        },
      })
      .then((res) => {
        setPage(1);
        setTotalRecords(res?.data?.count);
        let data = res?.data?.data?.map((val) => ({
          ...val,
          id: val?._id,
        }));
        setVehicles([...data]);
        setLoading(false);
      })
      .catch((err) => {
        setTotalRecords(0);
        setVehicles([]);
        setLoading(false);
      });
  }, [
    filterValues?.search,
    filterValues?.brands,
    filterValues?.models,
    filterValues?.status,
    filterValues?.priceMin,
    filterValues?.priceMax,
    page,
  ]);
  //#endregion useEffect

  //#region functions
  const handleClickOpenAddCustomer = () => {
    setOpenAddCustomer(true);
  };

  const handleCloseAddCustomer = () => {
    setOpenAddCustomer(false);
  };

  const handleClickOpenSelectDate = (vehicle) => {
    if (VehiclesDates[vehicle?._id]) {
      setSelectedDates(VehiclesDates[vehicle?._id]);
    }
    setSelectedVehicleCustom(vehicle);
    setSelectedVehicle(vehicle);
    setOpenSelectDate(true);
  };

  const handleCloseSelectDate = () => {
    setOpenSelectDate(false);
    setSelectedVehicle({});
    setSelectedDates([new Date(), new Date()]);
  };
  function isInService(strDate, inServiceDates) {
    return inServiceDates?.some(
      ([start, end]) =>
        new Date(strDate) >= new Date(start) &&
        new Date(strDate) <= new Date(end)
    );
  }
  function isUnAvailableDates(ranges, inServiceDates) {
    if (!ranges[1]) return false;
    return inServiceDates?.some(
      ([start, end]) =>
        new Date(ranges[0]) <= new Date(start) &&
        new Date(ranges[1]) >= new Date(end)
    );
  }
  //#endregion functions
  //#region rendering
  const cardTitle = {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "-0.03em",
    color: "#191919",
  };
  const subTitle = {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "-2%",
    color: "#656575",
  };
  const labelStyle = {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-2%",
    color: "#656575",
  };
  const priceStyle = {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24.2px",
    color: "#191919",
  };
  const priceSpanStyle = {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16.94px",
    color: "#656575",
  };
  const carCard = (vehicle, i) => {
    return (
      <Card
        key={i}
        sx={{
          padding: "20px !important",
          //   cursor: "pointer",
          border: SelectedCars?.includes(vehicle?._id)
            ? "1px solid #EF0A0A"
            : "unset",
          boxShadow: "0px 8px 24px 0px #4545501A",
        }}
      >
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, sm: 12, md: 12 }} sx={{ textAlign: "left" }}>
            <Stack direction={"column"}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography
                  sx={cardTitle}
                  variant="subtitle2"
                  textAlign={"left"}
                >
                  {vehicle?.carId}
                </Typography>
                {SelectedCars?.includes(vehicle?._id) && (
                  <Checkbox
                    color="secondary"
                    sx={{ padding: "0px" }}
                    checked={SelectedCars?.includes(vehicle?._id)}
                    onChange={(e) => {
                      // console.log(e.target.checked);
                      if (e.target.checked) {
                        setSelectedCars((prev) => [...prev, vehicle?._id]);
                      } else {
                        setSelectedCars((prev) =>
                          [...prev].filter((ele) => ele !== vehicle?._id)
                        );
                        let _VehiclesDates = { ...VehiclesDates };
                        delete _VehiclesDates[vehicle?._id];
                        setVehiclesDates(_VehiclesDates);
                      }
                    }}
                  />
                )}
              </Stack>
              <Typography variant="caption" sx={subTitle}>
                {vehicle?.model}
              </Typography>
            </Stack>
          </Grid>
          <Grid size={{ xs: 12, sm: 12, md: 12 }}>
            <img
              src={vehicle?.media[0]?.url}
              width="100%"
              height={"220px"}
              style={{ borderRadius: 2, objectFit: "contain" }}
              onLoad={() => {}}
              onError={(e) => {
                e.currentTarget.onerror = null; // prevents looping
                e.currentTarget.src = "/images/car.jpeg";
              }}
            />
          </Grid>
          <Grid size={{ xs: 4, sm: 4, md: 4 }}>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <img src="/images/brand.png" />
              <Typography variant="caption" sx={labelStyle}>
                {vehicle?.brand}
              </Typography>
            </Stack>
          </Grid>
          <Grid size={{ xs: 4, sm: 4, md: 5 }}>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <img src="/images/calendar-check.png" />
              <Typography variant="caption" sx={labelStyle}>
                {new Date(vehicle?.nextMaintenanceDate).toLocaleDateString()}
              </Typography>
            </Stack>
          </Grid>
          <Grid size={{ xs: 4, sm: 4, md: 3 }}>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <img src="/images/color.png" />
              <Typography variant="caption" sx={labelStyle}>
                {vehicle?.color}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            size={{ xs: 12, sm: 12, md: 12 }}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Typography sx={priceStyle} variant="body2" textAlign={"left"}>
                ${addCommas(vehicle?.rentalPrice)}/
              </Typography>
              <Typography variant="caption" sx={priceSpanStyle}>
                day
              </Typography>
            </Stack>
            <Button
              variant="contained"
              //size="small"
              sx={{ textTransform: "none" }}
              onClick={() => handleClickOpenSelectDate(vehicle)}
            >
              Select date
            </Button>
          </Grid>
        </Grid>
      </Card>
    );
  };
  const CustomerSection = (
    <CustomersFilter
      title="Select a customer"
      setFilterValues={setFilterValues}
      filterValues={filterValues}
      showCreateBtn={true}
      onClickAdd={handleClickOpenAddCustomer}
      AllCustomers={AllCustomers}
    />
  );
  const VehiclesFilterSection = (
    <VehiclesFilter
      title="Select a car"
      FilterData={FilterData}
      setFilterValues={setFilterValues}
      filterValues={filterValues}
      showCreateBtn={false}
    />
  );
  //#endregion rendering
  // console.log("VehiclesDates ", VehiclesDates);
  // console.log("SelectedCars ", SelectedCars);
  // console.log("AllVehiclesBookedDates ", AllVehiclesBookedDates);
  console.log("SelectedDates ", SelectedDates);
  // console.log("CustomSelectedVehicle ", CustomSelectedVehicle);
  return (
    <>
      <Grid container spacing={3} mb={2}>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>{CustomerSection}</Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>{VehiclesFilterSection}</Grid>
        {Loading && (
          <Grid size={{ xs: 12 }}>
            <CircularProgress />
          </Grid>
        )}
        {Vehicles?.slice((page - 1) * rowsPerPage, rowsPerPage * page)?.map(
          (vehicle, i) => (
            <Grid key={i} size={{ xs: 12, sm: 6, md: 3 }}>
              {carCard(vehicle, i)}
            </Grid>
          )
        )}
        <Grid size={{ xs: 12 }}>
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Pagination
              count={Math.ceil(TotalRecords / rowsPerPage)}
              page={page}
              onChange={handleChange}
              rowsPerPage={rowsPerPage}
            />
          </Box>
        </Grid>
        {!Loading && (
          <Grid mt={1} mb={1} size={{ xs: 12 }}>
            <Button
              sx={{ width: "150px", textTransform: "none" }}
              variant="contained"
              color="secondary"
              disabled={!CustomerData?._id || SelectedCars?.length === 0}
              onClick={() => {
                if (
                  Object.keys(VehiclesDates).sort()?.join("") !==
                  SelectedCars?.sort()?.join("")
                ) {
                  setError({
                    isError: true,
                    errorMessage:
                      "Please select a date for all selected vehicles.",
                    errors: "",
                  });
                } else if (!CustomerData?._id) {
                  setError({
                    isError: true,
                    errorMessage: "Please select a customer.",
                    errors: "",
                  });
                } else {
                  setError({
                    isError: false,
                    errorMessage: "",
                    errors: "",
                  });
                  navigate("/orders/inspect", {
                    state: {
                      CustomerData: CustomerData,
                      SelectedCars: AllVehicles?.filter((ele) =>
                        SelectedCars.includes(ele?._id)
                      ),
                      VehiclesDates: VehiclesDates,
                    },
                  });
                }
              }}
            >
              Rent Now
            </Button>
          </Grid>
        )}
      </Grid>

      {/**  Add customer Dialog */}
      <Dialog
        open={openAddCustomer}
        onClose={handleCloseAddCustomer}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            setLoadingAddCustomer(true);
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const name = formJson.name;
            const phoneNumber = formJson.phoneNumber;
            const email = formJson.email;
            const policyNumber = formJson?.policyNumber;
            const dateOfBirth = formJson?.dateOfBirth;
            const paymentMethod = formJson?.paymentMethod;
            const status = formJson?.status;

            let bodyData = new FormData();
            bodyData.append("name", name);
            bodyData.append("email", email);
            bodyData.append("phoneNumber", phoneNumber);
            bodyData.append(
              "dateOfBirth",
              dateOfBirth ? new Date(dateOfBirth).toISOString() : undefined
            );
            bodyData.append("policyNumber", policyNumber);
            bodyData.append("paymentMethod", paymentMethod);
            bodyData.append("status", status);
            axiosInstance.post(API.customers, bodyData).then((res) => {
              if (res?.data?.success === true || res?.data?.customer?._id) {
                setRefresh(!Refresh);
                setCustomerData(res?.data?.customer);
                handleCloseAddCustomer();
              }
              setLoadingAddCustomer(false);
            });
          },
        }}
      >
        <DialogTitle
          sx={{
            textTransform: "capitalize",
            ...theme.card_title,
            marginBottom: "0px",
            padding: "10px 24px 0px",
          }}
        >
          Customer Information
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ textTransform: "capitalize", ...theme.card_sub_title }}
          >
            Add customer Information
          </DialogContentText>
          <Typography mt={2} sx={{ ...theme.field_label }} variant="subtitle2">
            Name
          </Typography>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
          />
          <Typography sx={{ ...theme.field_label }} mt={1} variant="subtitle2">
            Phone number
          </Typography>
          <TextField
            required
            margin="dense"
            id="name"
            name="phoneNumber"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
          />
          <Typography sx={{ ...theme.field_label }} mt={1} variant="subtitle2">
            Email address
          </Typography>
          <TextField
            required
            margin="dense"
            id="name"
            name="email"
            type="email"
            fullWidth
            variant="outlined"
            size="small"
          />
          <Typography sx={{ ...theme.field_label }} mt={1} variant="subtitle2">
            Policy Number
          </Typography>
          <TextField
            margin="dense"
            name="policyNumber"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
          />
          <Typography sx={{ ...theme.field_label }} mt={1} variant="subtitle2">
            Date Of Birth
          </Typography>
          <LocalizationProvider
            sx={{
              width: "100% !important",
              "& .MuiTextField-root": { width: "100% !important" },
            }}
            dateAdapter={AdapterDayjs}
          >
            <DatePicker
              sx={{
                width: "100% !important",
                "& input": { width: "100%", padding: "8.5px 14px" },
              }}
              name="dateOfBirth"
              type="date"
              size="small"
            />
          </LocalizationProvider>
          <Typography sx={{ ...theme.field_label }} mt={1} variant="subtitle2">
            Payment Method
          </Typography>
          <Select
            name={"paymentMethod"}
            fullWidth
            size="small"
            placeholder="Select payment method"
          >
            {[
              { label: "online", value: "online" },
              { label: "bank transfer", value: "bank-transfer" },
              { label: "cash on delivery", value: "cash-on-delivery" },
            ]?.map((ele) => (
              <MenuItem key={ele.label} value={ele.value}>
                {ele.label}
              </MenuItem>
            ))}
          </Select>
          <Typography sx={{ ...theme.field_label }} mt={1} variant="subtitle2">
            Status
          </Typography>
          <Select
            name={"status"}
            fullWidth
            size="small"
            placeholder="Select status"
          >
            {["active", "inactive"]?.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{ width: "100%" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCloseAddCustomer}
              sx={{ width: "120px", textTransform: "none", marginRight: "5px" }}
            >
              Cancel
            </Button>
            <Button
              sx={{ width: "120px", textTransform: "none" }}
              variant="contained"
              color="secondary"
              type="submit"
            >
              {LoadingAddCustomer && (
                <CircularProgress
                  sx={{
                    width: "20px !important",
                    height: "20px !important",
                    marginRight: "20px",
                  }}
                />
              )}
              Add
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {/**  Select Date Dialog */}
      <Dialog
        open={openSelectDate}
        onClose={handleCloseSelectDate}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Grid container spacing={1}>
            <Grid size={{ xs: 12 }}>
              <Card sx={{ padding: "15px" }}>
                <Calendar
                  value={SelectedDates}
                  //onChange={setSelectedDates}
                  //multiple
                  range
                  className="red"
                  containerStyle={{ width: "100%" }}
                  style={{ width: "100%" }}
                  onChange={(ranges) => {
                    // let isClickedOutsideUnAvailbleDates =
                    //   AllVehiclesBookedDates[CustomSelectedVehicle?._id]?.every(
                    //     ([start, end]) =>
                    //       [ranges].some(
                    //         (range) =>
                    //           // range[0]?.format?.() === start &&
                    //           // range[1]?.format?.() === end
                    //           new Date(range[0]).toLocaleDateString() ===
                    //             new Date(start).toLocaleDateString() &&
                    //           new Date(
                    //             range[1] || range[0]
                    //           ).toLocaleDateString() ===
                    //             new Date(end).toLocaleDateString()
                    //       )
                    //   );
                    //if (!isClickedOutsideUnAvailbleDates) return false;

                    let _isUnAvailableDates = isUnAvailableDates(
                      ranges,
                      AllVehiclesBookedDates[CustomSelectedVehicle?._id]
                    );
                    if (_isUnAvailableDates) return false;
                    setSelectedDates(ranges);
                  }}
                  mapDays={({
                    date,
                    // today,
                    // selectedDate,
                    // currentMonth,
                    // isSameDate,
                  }) => {
                    let className;
                    let strDate = date; //.format();
                    let _isInService = isInService(
                      strDate,
                      AllVehiclesBookedDates[CustomSelectedVehicle?._id]
                    );

                    //if (isReserved(strDate)) className = "reserved";
                    console.log(
                      "isInService ",
                      isInService(
                        strDate,
                        AllVehiclesBookedDates[CustomSelectedVehicle?._id]
                      )
                    );
                    if (_isInService) className = "in-service";
                    // if (className) return { className };

                    return {
                      disabled: _isInService ? true : false,
                      children: (
                        <div
                          className={className}
                          style={{
                            padding: "0px 13px",
                            fontSize: "16px",
                            lineHeight: "25px",
                          }}
                        >
                          <div>{date.format("D")}</div>
                        </div>
                      ),
                    };
                  }}
                />
              </Card>
            </Grid>
            <Grid
              size={{ xs: 12 }}
              sx={{ "& .MuiFormControl-root": { width: "100%" } }}
            >
              <Typography variant="subtitle2" textAlign={"left"}>
                Pick up time
              </Typography>
              <LocalizationProvider
                sx={{ "& .MuiTextField-root": { width: "100% !important" } }}
                dateAdapter={AdapterDayjs}
              >
                <TimePicker
                  sx={{
                    "& input": {
                      padding: "8.5px 14px",
                      width: "100%",
                    },
                  }}
                  size="small"
                  value={dayjs(SelectedDates[0])}
                  onChange={(newValue) => {
                    console.log(newValue);
                    setSelectedDates((prev) => [new Date(newValue), prev[1]]);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              size={{ xs: 12 }}
              sx={{ "& .MuiFormControl-root": { width: "100%" } }}
            >
              <Typography variant="subtitle2" textAlign={"left"}>
                Return up time
              </Typography>
              <LocalizationProvider
                sx={{ "& .MuiTextField-root": { width: "100% !important" } }}
                dateAdapter={AdapterDayjs}
              >
                <TimePicker
                  sx={{
                    "& input": {
                      padding: "8.5px 14px",
                      width: "100%",
                    },
                  }}
                  size="small"
                  value={dayjs(SelectedDates[1])}
                  onChange={(newValue) =>
                    setSelectedDates((prev) => [prev[0], new Date(newValue)])
                  }
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{ width: "100%" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCloseSelectDate}
              sx={{ width: "120px", textTransform: "none", marginRight: "5px" }}
            >
              Cancel
            </Button>
            <Button
              sx={{ width: "120px", textTransform: "none" }}
              variant="contained"
              color="secondary"
              type="submit"
              onClick={() => {
                setSelectedCars((prev) => [
                  ...new Set([...prev, SelectedVehicle?._id]),
                ]);
                setVehiclesDates((prev) => ({
                  ...prev,
                  [SelectedVehicle?._id]: SelectedDates?.map(
                    (ele) => new Date(ele)
                  ),
                }));
                handleCloseSelectDate();
              }}
            >
              Apply
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={Error.isError}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() =>
            setError({
              isError: false,
              errorMessage: "",
              errors: "",
            })
          }
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {Error.errorMessage}
          {Error?.errors && <br />}
          {Error?.errors || ""}
        </Alert>
      </Snackbar>
    </>
  );
}

export default CreateOrder;
