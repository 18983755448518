import React, { createContext } from "react";

export const AppContext = createContext(null);

export const AppContextProvider = ({ children }) => {
  const [ShowBackButton, setShowBackButton] = React.useState(false);

  const defaultContext = {
    ShowBackButton,
    setShowBackButton,
  };

  return (
    <AppContext.Provider value={defaultContext}>{children}</AppContext.Provider>
  );
};
